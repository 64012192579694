import { graphql } from "gatsby";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import React from "react";
import Typed from "react-typed";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { JobList } from "../components/JobList";
import { mailTo } from "../config";
import MainLayout from "../layout/MainLayout";

export default ({ data }) => {
  const jobs = data.allFile.edges.map((edge) => edge.node.childMarkdownRemark);

  return (
    <MainLayout>
      <Jumbotron />
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="card text-center bg-light border-dark">
              <div className="card-body">
                <h5 className="card-title">Never miss a Job! 📬</h5>
                <p className="card-text">
                  Sign up to our newsletter to get updates about jobs delivered right to your inbox.
                </p>
                <div id="mc_embed_signup">
                  <form
                    action="https://nomadbrains.us4.list-manage.com/subscribe/post?u=05c6714fc2b3d5976aaf8e0e0&amp;id=9abc32a555"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    className="validate"
                    target="_blank"
                    noValidate
                  >
                    <div className="input-group">
                      <input
                        type="email"
                        name="EMAIL"
                        className="email form-control"
                        id="mce-EMAIL"
                        placeholder="your@email.com"
                        required
                        style={{ textAlign: "center" }}
                      />
                      <div style={{ position: "absolute", left: -5000 }} aria-hidden="true">
                        <input type="text" name="b_05c6714fc2b3d5976aaf8e0e0_9abc32a555" tabIndex={-1} value="" />
                      </div>
                      <div className="input-group-append">
                        <input
                          type="submit"
                          value="Subscribe"
                          name="subscribe"
                          id="mc-embedded-subscribe"
                          className="btn btn-primary"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-10 offset-lg-1 col-xl-10 offset-xl-1">
            <JobList jobs={jobs} />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

const Jumbotron = () => (
  <div
    className="jumbotron jumbotron-fluid"
    style={{
      background: "#F7F9FC",
      borderBottom: "1px solid #DEE0F2",
      textAlign: "center",
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-10 offset-lg-1 col-xl-10 offset-xl-1">
          <h1 className="display-4">
            Find your next job as a <br />
            <Typed
              strings={[
                "Sales Cloud Consultant",
                "Technical Architect",
                "Administrator",
                "Solution Architect",
                "Service Cloud Consultant",
                "Developer",
              ]}
              typeSpeed={30}
              backDelay={1300}
              loop={true}
            />
            &nbsp;
          </h1>
          <div className="lead">
            Platform Jobs is the <strong>largest Salesforce-focused work community in the world</strong>
            .<br />
            It is the best place to grow your career in the Salesforce ecosystem and find talent!
          </div>

          <a
            className="btn btn-primary btn-lg mt-4"
            href={mailTo}
            role="button"
            onClick={(e) => {
              // e.preventDefault();
              if (typeof window !== "undefined" && window.gtag)
                //@ts-ignore
                window.gtag("event", "click", {
                  // string - required - The object that was interacted with (e.g.video)
                  category: "post-job-header",
                  // string - required - Type of interaction (e.g. 'play')
                  action: "click",
                  // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                  label: "Post Job",
                });
            }}
          >
            Post a Job for £250
          </a>
        </div>
      </div>
    </div>
  </div>
);

export const query = graphql`
  {
    allFile(
      sort: { fields: childMarkdownRemark___frontmatter___date, order: DESC }
      filter: { sourceInstanceName: { eq: "jobs" }, childMarkdownRemark: { frontmatter: { hidden: { eq: false } } } }
      limit: 20
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              company {
                title
                id
              }
              dateFromNow: date(fromNow: true)
              date
              location
              title
              slug
              roleType
              # tags
              sponsored
            }
            html
          }
        }
      }
    }
  }
`;
